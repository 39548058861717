export const FIREBASE_CONFIG_STAGING = {
  apiKey: 'AIzaSyATog9rUuLFicTRJzcMFjPgXAqDS_ohZ_o',
  authDomain: 'wastex-staging.firebaseapp.com',
  projectId: 'wastex-staging',
  storageBucket: 'wastex-staging.appspot.com',
  messagingSenderId: '286124165794',
  appId: '1:286124165794:web:74fdcf438d0877646ee5fb',
}

export const FIREBASE_CONFIG_PRODUCTION = {
  apiKey: 'AIzaSyB2NwWs5mbnjf_tIo4gKswlGRwRuJB9Vzo',
  authDomain: 'wastex-prod.firebaseapp.com',
  projectId: 'wastex-prod',
  storageBucket: 'wastex-prod.appspot.com',
  messagingSenderId: '524950098',
  appId: '1:524950098:web:40e080e655935b0a179d8b',
}

export const FRONT_END_URI: { [key in string]: string } = {
  staging: 'https://staging.wastex.tech/',
  demo: '',
  production: 'https://app.wastex.tech/',
  default: 'localhost:3333',
}

export const BACKEND_URL_DEV = `${window.location.protocol}//${window.location.hostname}:8080`
export const BACKEND_URL_STAGING = 'https://node-nest-api-xtq7ravppq-ew.a.run.app/'
export const BACKEND_URL_DEMO = 'https://node-api-demo-xmq7eperwq-ew.a.run.app'
export const BACKEND_URL_PRODUCTION = 'https://node-nest-api-xmq7eperwq-ew.a.run.app'

// export const GRAPHQL_URL_DEV = "https://sincere-lemur-60.hasura.app/v1/graphql";
export const GRAPHQL_URL_DEV = 'https://graphql-engine-xtq7ravppq-ew.a.run.app/v1/graphql'
export const GRAPHQL_URL_STAGING = 'https://graphql-engine-xtq7ravppq-ew.a.run.app/v1/graphql'
export const GRAPHQL_URL_DEMO = 'https://graphql-engine-demo-xmq7eperwq-ew.a.run.app/v1/graphql'
export const GRAPHQL_URL_PRODUCTION = 'https://graphql-engine-xmq7eperwq-ew.a.run.app/v1/graphql'

export const TRACKDECHETS_CLIENT_ID_STAGING = 'clwonq9qs03lv14fdqug0l3nv'
export const TRACKDECHETS_CLIENT_ID_PRODUCTION = 'cly2q4ho78j6310za64jk5uxw'
export const TRACKDECHETS_REDIRECT_URI_STAGING = 'https://staging.wastex.tech/oauth2/trackdechets/callback'
export const TRACKDECHETS_REDIRECT_URI_PRODUCTION = 'https://app.wastex.tech/oauth2/trackdechets/callback'

export function getEnv() {
  const { hostname } = window.location
  if (
    hostname.includes('refacto')
    || hostname.includes('staging')
    || hostname.includes('backoffice-staging.')
    || hostname.includes('recycler-staging.')
  ) {
    return 'staging'
  }
  if (hostname.includes('demo') || hostname.includes('backoffice-demo.') || hostname.includes('recycler-demo.')) {
    return 'demo'
  }
  else if (
    hostname.includes('prod')
    || hostname.includes('app.')
    || hostname.includes('backoffice.')
    || hostname.includes('recycler.')
  ) {
    return 'production'
  }
  else {
    return 'dev'
  }
}

export function getBackEndURL() {
  const env = getEnv()
  if (env === 'staging')
    return BACKEND_URL_STAGING
  else if (env === 'demo')
    return BACKEND_URL_DEMO
  else if (env === 'production')
    return BACKEND_URL_PRODUCTION
  else return BACKEND_URL_DEV
  // else return BACKEND_URL_PRODUCTION;
}

export function getFrontEndURL() {
  const env = getEnv() as string
  return FRONT_END_URI[env] ? FRONT_END_URI[env] : FRONT_END_URI.default
}

export function getGraphQLdURL() {
  const env = getEnv()
  if (env === 'staging')
    return GRAPHQL_URL_STAGING
  else if (env === 'demo')
    return GRAPHQL_URL_DEMO
  else if (env === 'production')
    return GRAPHQL_URL_PRODUCTION
  else return GRAPHQL_URL_DEV
  // else return GRAPHQL_URL_PRODUCTION;
}

export function getFirebaseConfig() {
  const env = getEnv()
  if (env === 'staging')
    return FIREBASE_CONFIG_STAGING
  else if (env === 'demo')
    return FIREBASE_CONFIG_PRODUCTION
  else if (env === 'production')
    return FIREBASE_CONFIG_PRODUCTION
  else return FIREBASE_CONFIG_STAGING
  // else return FIREBASE_CONFIG_PRODUCTION;
}
